import * as React from "react";

class JustOverTheFooter extends React.Component {
    render() {

        return (
            <section className="hg_section pt-60 pb-0 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="keywordbox d-flex justify-content-center">
                                <h4 className="title-text fs-xl align-self-center">
                                    BitSpreader - one-stop-shop trading platform for Bitcoin Futures Spread Trading
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default JustOverTheFooter;