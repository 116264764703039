import * as React from "react"

class SoWhyBitSpreader extends React.Component {
    render() {
        return (
            <section className="hg_section bg-light pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div
                                className="kl-title-block clearfix tbk--text-default tbk--center text-center tbk-symbol--border2 tbk-icon-pos--after-title">
                                <h3 className="tbk__title fs-m tcolor fw-bold">
                                <span className="tbk__border-helper">
									SO WHY BITSPREADER, ANYWAY?
								</span>
                                </h3>

                                <div className="tbk__symbol "/>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                FAST AND RELIABLE
                            </h3>

                            <p>
                                It is fast and reliable, our cloud-based infrastructure assures low latency and short execution times
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                WELL DOCUMENTED
                            </h3>

                            <p>
                                Documentation included with explanations for most of the options.
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                 TRADE AUTOMATION
                            </h3>

                            <p>
                                BitSpreader will execute your spread orders automatically based on provided criteria
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                EXPOSURE MANAGEMENT
                            </h3>

                            <p>
                                Manage your outright exposure in case any of the legs can't be executed
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-12">
                            <div className="inv_separator clearfix">
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                INTEGRATIONS
                            </h3>

                            <p>
                                High standard of performance and error handling, growing base of integrated exchanges
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                BOTS ARE COMING
                            </h3>

                            <p>
                                We are working on even more automation with custom trading strategies
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-20">
                            <h3 className="fs-s black fw-bold">
                                IMPLIED ORDER BOOK
                            </h3>

                            <p>
                                Real time constructed and streamed implied cross-market order book
                            </p>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <h3 className="fs-s black fw-bold">
                                DETAILED AUDIT TRAIL
                            </h3>

                            <p>
                                BitSpreader allows for the real time monitoring of each order execution
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default SoWhyBitSpreader