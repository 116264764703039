import * as React from "react";

class ScreenshotBox1 extends React.Component {
    render() {
        return (
            <section className="hg_section pt-40 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="screenshot-box">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                        <div className="left-side pt-0">
                                            <h3 className="title mt-0 p-1 section-title">
                                            <span className="fw-thin">BitSpreader <span
                                                className="fw-semibold">Crypto Spread Trading</span></span>
                                            </h3>

                                            <ul className="features">
                                                <li>
                                                    <h4>
                                                        intra and cross-exchange spreads
                                                    </h4>

                                                    <p>
                                                        - implied orderbooks composed in real time
                                                        <br/>- intra and cross-exchange spread positions management
                                                    </p>
                                                </li>


                                                <li>
                                                    <div className={"row"}>
                                                        <div className={"col-md-12 pr-0"}>
                                                            <h4>
                                                                Auto - spreading
                                                            </h4>

                                                            <p>
                                                                Low latency, high-frequency spread trading method realised with use of limit orders (market making)
                                                            </p>
                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <h4>
                                                        Advanced Spread Charts
                                                    </h4>

                                                    <p>
                                                        We gather real time spread data that we provide to you for<br/> historical analysis.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                        <div className="screenshot-slider">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/69EWOoxG38A" title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                                                    style={{
                                                display: "block",
                                                margin: "0 auto",
                                                maxWidth: "100%",
                                                paddingBottom: "10px"
                                            }}></iframe>
                                            <div className="screenshotBox-slickNav clearfix"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ScreenshotBox1;