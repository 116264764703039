import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

class AutoSpreadingFeatures extends React.Component {
    render() {
        return (
            <section className="hg_section pt-40 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block tbk-symbol--line tbk-icon-pos--after-title pb-0">
                                <h2 className="tbk__title fs-xxl fw-extrabold gray">
                                    <strong>Auto spreading features</strong>
                                </h2>

                                <div className="tbk__symbol">
                                    <span></span>
                                </div>


                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="kl-title-block tbk-symbol--line">
                                <img src="/staticimg/screenshots/advanced-spreading-options.png"
                                     className={"shadow "}
                                     style={{width: "350"}}/>

                                <div className="mb-50 mt-50">
                                    <a href="#" target="_blank"
                                       className="btn-element btn btn-lined btn-skewed lined-dark"
                                       title="Auto spreading platform">
                                        DISCOVER NOW
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">
                                            <div className="kl-iconbox__icon-wrapper text-danger fs-xxxl">

                                                <div className="kl-iconbox__icon-wrapper">
                                                    <StaticImage src={"../../gatsbyimg/advanced-details-tr.png"} width={66} height={66} alt={"market"} placeholder={"none"} className="kl-iconbox__icon " />
                                                </div>
                                            </div>

                                            <div className="kl-iconbox__content-wrapper">
                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">Advanced
                                                        quoting options</h3>
                                                </div>

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                                    <p className="kl-iconbox__desc fs-xn gray">Embrace a spectrum of quoting tools designed to deduce optimal pricing in the market, enhancing your trading efficiency and competitiveness.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">
                                            <div className="kl-iconbox__icon-wrapper text-danger fs-xxxl">
                                                <StaticImage src={"../../gatsbyimg/icebrg-tr.png"} width={66} height={66} alt={"market"} placeholder={"none"} className="kl-iconbox__icon"/>

                                            </div>

                                            <div className="kl-iconbox__content-wrapper">
                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">Iceberg
                                                        orders</h3>
                                                </div>

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                                    <p className="kl-iconbox__desc fs-xn gray">Ideal for high-volume traders seeking to execute large orders while preserving price integrity and minimizing visibility</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">
                                            <div className="kl-iconbox__icon-wrapper text-danger fs-xxxl">
                                                <StaticImage src={"../../gatsbyimg/hedging-tr.png"} width={66} height={66} alt={"market"} placeholder={"none"} className="kl-iconbox__icon"/>

                                            </div>

                                            <div className="kl-iconbox__content-wrapper">
                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">Hedging
                                                        strategies</h3>
                                                </div>

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                                    <p className="kl-iconbox__desc fs-xn gray">Choose the best hedging option to reduce exposure or protect against market fluctuations, tailoring your strategy to market conditions and investment goals.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">
                                            <div className="kl-iconbox__icon-wrapper text-danger fs-xxxl">
                                                <StaticImage src={"../../gatsbyimg/network-tr.png"} width={66} height={66} alt={"market"} placeholder={"none"} className="kl-iconbox__icon"/>

                                            </div>

                                            <div className="kl-iconbox__content-wrapper">
                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">Execution
                                                        geolocation selection</h3>
                                                </div>

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                                    <p className="kl-iconbox__desc fs-xn gray">Choose your order's execution location to reduce latency, improve performance, and gain a competitive edge in global trading.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default AutoSpreadingFeatures;