import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import MarketFeed from "../marketfeed/MarketFeed";

class SampleSpreads extends React.Component {
    render() {
        return (
            <section className="hg_section pt-80 pb-40 bg-light">
                <div className="container">
                    <div className="row">


                        <div className="col-sm-12 col-md-12 col-lg-5">

                            <div className="kl-title-block tbk-symbol--line">

                                <h3 className="tbk__title fs-xxl fw-extrabold gray">
                                   Trade on <br/> over 120 synthetic <br/>cross-exchange calendar spreads!
                                </h3>


                                <div className="tbk__symbol">
                                    <span></span>
                                </div>



                                <p>
                                    <strong>Over 120 </strong> synthetic intra and inter exchange calendar spreads. We still adding new integrations to make our portfolio richer.
                                </p>


                                <div className="mb-50">
                                    <a href="https://platform.bitspreader.com"
                                       className="btn-element btn btn-lined btn-skewed lined-dark"
                                       title="">
                                        SIGN UP AND DISCOVER BITCOIN SPREAD TRADING
                                    </a>
                                </div>
                            </div>

                        </div>


                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <div className="row">
                                <MarketFeed/>
                            </div>
                        </div>

                    </div>


                </div>

            </section>
        );
    }
}

export default SampleSpreads
;