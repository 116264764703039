import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import MarketFeed from "../marketfeed/MarketFeed";

class GeoColocation extends React.Component {
    render() {
        return (
            <section className="hg_section pt-80 pb-40 bg-white">
                <div className="container">
                    <div className="row">


                        <div className="col-sm-12 col-md-12 col-lg-5">

                            <div className="kl-title-block tbk-symbol--line">

                                <h3 className="tbk__title fs-xxl fw-extrabold gray">
                                    Geographical <br/>Co-Location for <br/>Lightning-Fast Execution Times!
                                </h3>


                                <div className="tbk__symbol">
                                    <span></span>
                                </div>

                                <p className={"text-justify"}>
                                    BitSpreader now offers geographical co-location of servers, providing traders with the fastest possible execution times. By strategically placing servers across the world in the United States, Europe, and Japan, users can now choose the location that guarantees the best execution times for their trades.

                                    This new feature is designed with the needs of serious traders in mind, ensuring minimal risk of slippage and maximum profits. With BitSpreader's geographical co-location, you can take your trading to the next level and trade with confidence.
                                </p>


                            </div>

                        </div>


                        <div className="col-sm-12 col-md-12 col-lg-7 my-auto">

                                <StaticImage src="../../gatsbyimg/worldmap2.jpeg"/>
                        </div>

                    </div>


                </div>

            </section>
        );
    }
}

export default GeoColocation
;