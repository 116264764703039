import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";

import MainHero from "../page_components/home/MainHero";
import JustOverTheFooter from "../page_components/home/JustOverTheFooter";
import MarketNeutralStrategies from "../page_components/home/MarketNeutralStrategies";
import ScreenshotBox1 from "../page_components/home/ScreenshotBox1";
import ScreenshotBox2 from "../page_components/home/ScreenshotBox2";
import SoWhyBitSpreader from "../page_components/home/SoWhyBitSpreader";
import Integrations from "../page_components/home/Integrations";
import SampleSpreads from "../page_components/home/SampleSpreads";
import SecuritySection from "../page_components/common/SecuritySection";
import GeoColocation from "../page_components/common/GeoColocation";
import AutoSpreadingFeatures from "../page_components/home/AutoSpreadingFeatures";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Home"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <MainHero/>
                <MarketNeutralStrategies/>
                <ScreenshotBox1/>
                <AutoSpreadingFeatures/>
                <SampleSpreads/>
                <SoWhyBitSpreader/>
                <GeoColocation/>
                <ScreenshotBox2/>

                <SecuritySection/>
                <Integrations/>
                <JustOverTheFooter/>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
