import * as React from "react";

class MarketNeutralStrategies extends React.Component {
    render() {
        return (
            <section className="hg_section bg-lightgray pt-10 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="callout-banner clearfix mb-10">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-8">
                                        <h3>
                                            <span className="fw-thin fs-s text-justify">
                                                Cryptocurrency <span
                                                className="fw-semibold">futures spread trading</span> is type of <span
                                                className="fw-semibold">market-neutral strategy</span> where the investors seek to profit from the change in the price differences between two futures contracts
                                                with the use of <span
                                                className="fw-semibold">auto-spreading & market making software</span>

                                            </span>
                                        </h3>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 justify-content-center align-self-center">
                                        <div className={"row p-0 m-0"}>
                                            <div className={"col-12 p-0 m-0"}>
                                                <a href="/futuresspreadtrading" className="w-100 btn-element btn btn-fullcolor btn-skewed all-caps" target="_self">LEARN MORE ABOUT FUTURES SPREAD TRADING</a>
                                            </div>
                                        </div>
                                        <div className={"row p-0 m-0 pt-10"}>
                                            <div className={"col-12 p-0 m-0"}>
                                                <a href="/services" className="w-100 btn-element btn btn-lined lined-dark btn-skewed all-caps" target="_self">OUR SERVICES</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    }
}

export default MarketNeutralStrategies;