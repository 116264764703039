import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

class MainHero extends React.Component {
    render() {
        return <div className="kl-slideshow static-content__slideshow uh_light_gray scrollme">
            <div className="bgback">
            </div>

            <div className="kl-slideshow-inner static-content__wrapper static-content--height min-600 d-flex">
                <div className="static-content__source animateme" data-when="span" data-from="0" data-to="0.75" data-easing="linear" data-translatey="300" data-opacity="0.1">
                    <div className="kl-bg-source">
                        <div className="kl-bg-source__bgimage"
                             style={{
                                 backgroundImage: "url(/staticimg/heros/mainhero.jpeg)",
                                 backgroundRepeat: "no-repeat",
                                 backgroundAttachment: "scroll",
                                 backgroundPositionX: "center",
                                 backgroundPositionY: "center",
                                 backgroundSize: "cover"
                             }}>
                        </div>

                        <div className="kl-bg-source__overlay"
                             style={{
                                 background: "rgba(30,115,190,0.3)",
                                 background: "-moz-linear-gradient(left, rgba(30,115,190,0.3) 0%, rgba(53,53,53,0.4) 100%)",
                                 background: "-webkit-gradient(linear, left top, right top, color-stop(0%,rgba(30,115,190,0.3)), color-stop(100%,rgba(53,53,53,0.4)))",
                                 background: "-webkit-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "-o-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "-ms-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                                 background: "linear-gradient(to right, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)"
                             }}>
                        </div>
                    </div>

                    <div className="th-sparkles"></div>
                </div>
                <div className="kl-bg-source__overlay"
                     style={{
                         background: "rgba(30,115,190,0.3)",
                         background: "-moz-linear-gradient(left, rgba(30,115,190,0.3) 0%, rgba(53,53,53,0.4) 100%)",
                         background: "-webkit-gradient(linear, left top, right top, color-stop(0%,rgba(30,115,190,0.3)), color-stop(100%,rgba(53,53,53,0.4)))",
                         background: "-webkit-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "-o-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "-ms-linear-gradient(left, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)",
                         background: "linear-gradient(to right, rgba(30,115,190,0.3) 0%,rgba(53,53,53,0.4) 100%)"
                     }}>
                </div>

                <div className="container align-self-center">
                    <div className="kl-slideshow-safepadding sc__container animateme pt-100" data-when="span"
                         data-from="0"
                         data-to="0.75" data-translatey="200" data-opacity="0.1" data-easing="linear"
                         style={{
                             opacity: 1,
                             transform: "translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1)"
                         }}>
                        <div className={'row'}>
                            <div className={'col-md-6'}>


                                <div className="static-content default-style pl-20">
                                    <h3 className="static-content__subtitle text-left">
                                        <span className="fw-thin ">
                                            Cryptocurrency
                                            <br/><span className={"fw-semibold"}>Futures Spread Trading</span>
                                            <br/>Platform
                                        </span>
                                    </h3>
                                    <div className="static-content__infopop animated fadeBoxIn sc-infopop--left" data-arrow="top">
                                        <a className="sc-infopop__btn" href="https://platform.bitspreader.com/register">
                                            SIGN UP
                                        </a>
                                        <h5 className="sc-infopop__text">
                                            High Frequency <strong>auto-spreader</strong>
                                        </h5>
                                        <div className="clear">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-6 pt-50'}>
                                <StaticImage src="../../gatsbyimg/screenshots/mainheroscreens.png" width="1000"
                                             height="624"
                                             placeholder={"none"}
                                             alt="BitSpreader main screen"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default MainHero;