import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faLock, faMobile,
    faUserShield,
} from '@fortawesome/free-solid-svg-icons'

class SecuritySection extends React.Component {
    render() {
        return (
            <section className="hg_section pt-80 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block text-center">
                                <h3 className="tbk__title fs-xxl fw-thin">
                                    <strong>YOUR SECURITY</strong><span className="fw-semibold fs-xxxl fs-xs-xl tcolor"> is very important to us</span>
                                    <div style={{height: "50px"}}></div>
                                </h3>
                            </div>
                            <div className={"row"}>


                                <div className={'col-sm-12 col-lg-4'}>
                                    <div className="text-center pb-20">
                                        <FontAwesomeIcon icon={faUserShield} size="4x"/>
                                    </div>

                                    <h4 className={'text-center fw-semibold'}>
                                        We never touch your funds
                                    </h4>

                                    <p className={'text-justify'}>
                                        Your funds are stored safely on your exchange account, BitSpreader just executes
                                        orders on your behalf on the exchange using the API key that you have provided.
                                    </p>
                                    <p className={'text-justify'}>
                                        BitSpreader <strong>never does any transfers of your funds</strong>.
                                    </p>

                                    <p className={'text-justify'}>
                                        For further increase of security we strongly encourage you to not provide any
                                        API keys that have privilege to execute transfers - please refer to your
                                        exchange API key settings to make sure to reduce the API key privileges just to
                                        the most necessary (balance, history, trading).
                                    </p>
                                </div>
                                <div className={'col-sm-12 col-lg-4'}>
                                    <div className="text-center pb-20">
                                        <FontAwesomeIcon icon={faLock} size="4x"/>
                                    </div>

                                    <h4 className={'text-center fw-semibold'}>
                                        Your data is encrypted
                                    </h4>

                                    <p className={'text-justify'}>
                                        Your API keys are encrypted with highest security standards and available only in the internal layer of the
                                        BitSpreader services safely hidden behind the firewalls and not accessible from
                                        the website. Encryption keys are safely stored in the digital vault.
                                    </p>
                                    <p className={'text-justify'}>
                                        For managing your API keys we follow <strong>write-only pattern</strong> - once
                                        you have provided your API key, the BitSpreader has stored it internally for the
                                        trading purposes and doesn't expose it even to you for editing. If you need to
                                        update the API key - you need to delete the old key and provide the updated one. This approach secures your keys in case you lose access to your BitSpreader account.
                                    </p>
                                </div>
                                <div className={'col-sm-12 col-lg-4'}>
                                    <div className="text-center pb-20">
                                        <FontAwesomeIcon icon={faMobile} size="4x"/>
                                    </div>

                                    <h4 className={'text-center fw-semibold'}>
                                        Two Factor Authentication is a MUST
                                    </h4>

                                    <p className={'text-justify'}>
                                        In order to further increase your security we require ALL the users to use
                                        two-factor authentication. Every time you sign in to the platform you need to
                                        provide security code generated by the two-factor application on your mobile
                                        phone - ie <b>Google Authenticator</b> or <b>FreeOTP</b> that needs to be set up
                                        during the registration process.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


        );

    }
}

export default SecuritySection;